const ParticipantsNameSpace = 'participants';

const ParticipantsActionTypes = Object.freeze({
    GetParticipants: 'GetParticipants',
    AddLike: 'AddLike',
});

const ParticipantsMutationTypes = Object.freeze({
    SetLikedId: 'SetLikedId',
});

const ParticipantsGetterTypes = Object.freeze({
    GetLikedId: 'GetLikedId',
});

export {
    ParticipantsNameSpace,
    ParticipantsActionTypes,
    ParticipantsMutationTypes,
    ParticipantsGetterTypes,
};