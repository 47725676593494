import { UserPostsActionTypes, UserPostsMutationTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [UserPostsActionTypes.GetUserPosts]({ commit }, { params } = {}) {
        return new Promise((resolve, reject) => {
            this.$api.getUserPosts.get({
                params: params ? { ...params} : '',
            }).then(({data}) => {
                if (!data.error_message) {
                    commit(`${UserPostsMutationTypes.SetUserPosts}`, { user_posts: data.data.data })
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data.error_message)
                }
            })
            .catch((error) => {
                notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [UserPostsActionTypes.GetTotal]({ commit }, { params } = {}) {
        return new Promise((resolve, reject) => {
            this.$api.getTotal.get({
                params: params ? { ...params} : '',
            }).then(({data}) => {
                if (!data.error_message) {
                    commit(`${UserPostsMutationTypes.SetTotal}`, { total: data.data })
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data.error_message)
                }
            })
            .catch((error) => {
                notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    async [UserPostsActionTypes.AddUserPost](context, { 
        firstname,
        lastname,
        patronymic,
        date_of_birth,
        phone,
        social,
        attachment,
        links,
        vuz,
        ssyz,
        competitive,
     } = {}) {
        try {
            const { data } = await this.$api.addUserPost.post({
                body: {
                    firstname,
                    lastname,
                    patronymic,
                    date_of_birth,
                    phone,
                    social,
                    attachment,
                    links,
                    vuz,
                    ssyz,
                    competitive,
                }
            })
            
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })  
            } else {
                notifySuccess({ title: data.message })
            }

            return data
        } catch (error) {
            notifyDefaultError({ error })
        }            
    },
    async [UserPostsActionTypes.EditUserPost]({ commit }, { id, value }) {
        try {
            const { data } = await this.$api.editUserPost.patch({ id, body: {
                ...value
            } })
            if (!data.error_message) {
                commit(UserPostsMutationTypes.SetChangedUserPost, {user_post: data.data})
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    async [UserPostsActionTypes.DeleteUserPost]({ commit }, { id }) {
        try {
            const { data } = await this.$api.deleteUserPost.delete({ 
                body: { id: id } 
            })
            if (!data.error_message) {
                commit(UserPostsMutationTypes.SetDeletedUserPost, { id })
                notifySuccess({ title: data.message })
            } else {
                notifyError({ title: data.error_message, errors: data.errors })
            }
        } catch (error) {
            notifyDefaultError({ error })
        }
    },
    //To Do Find User Post
    // async [UserPostsActionTypes.FindUserPost]({ commit }, { search }) {
    //     return new Promise((resolve, reject) => {
    //         this.$api.findUserPost.get({
    //             params: { search },
    //         }).then(({data}) => {
    //             if (!data.error_message) {
    //                 commit(`${UserPostsMutationTypes.SetUsers}`, { user_posts: data.data.data })
    //                 notifySuccess({ title: data.message })
    //                 resolve({data})
    //             } else {
    //                 notifyError({ title: data.error_message, errors: data.errors })
    //                 resolve(data.error_message)
    //             }
    //         })
    //         .catch((error) => {
    //             notifyDefaultError({ error })
    //             reject(error)
    //         })
    //     })
    // },
}