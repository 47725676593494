import { QuizActionTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [QuizActionTypes.AddQuiz](context, { 
        firstname,
        lastname,
        patronymic,
        answers,
        quiz_result,
     } = {}) {
        try {
            const { data } = await this.$api.addQuiz.post({
                body: {
                    firstname,
                    lastname,
                    patronymic,
                    answers,
                    quiz_result,
                }
            })
            
            if (data.error_message) {
                notifyError({ title: data.error_message, errors: data.errors })  
            } else {
                notifySuccess({ title: data.message })
            }

            return data
        } catch (error) {
            notifyDefaultError({ error })
        }            
    },    
}