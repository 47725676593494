import { ParticipantsActionTypes } from './types';
import { notifyError, notifySuccess, notifyDefaultError } from '../../helpers/notifications';

export const actions = {
    async [ParticipantsActionTypes.GetParticipants](context, { params } = {}) {
        return new Promise((resolve, reject) => {
            this.$api.getParticipants.get({
                params: params ? { ...params} : '',
            }).then(({data}) => {
                if (!data.error_message) {
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data.error_message)
                }
            })
            .catch((error) => {
                notifyDefaultError({ error })
                reject(error)
            })
        })
    },
    
    async [ParticipantsActionTypes.AddLike](context, { id, value }) {
        return new Promise((resolve, reject) => {
            this.$api.addLike.patch({ id, body: {
                ...value
            } })
            .then(({data}) => {
                if (!data.error_message) {
                    notifySuccess({ title: data.message })
                    resolve({data})
                } else {
                    notifyError({ title: data.error_message, errors: data.errors })
                    resolve(data.error_message)
                }
            })
            .catch((error) => {
                notifyDefaultError({ error })
                reject(error)
            })

        })
    },
    
}