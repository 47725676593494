<template lang="pug">
  #app.font-arial
    router-view
    v-dialog
    //modal(name="winner" :height="500")
    //  .p-4.flex.flex-col.justify-between.h-full.items-center.border-4
    //    .flex.flex-col.items-center
    //      .w-150.bg-red-500.mb-20(style="height:2px")
    //      .text-3xl.font-cormorant.text-center Поздравляем победителя проекта
    //      .text-5xl.font-cormorantBold.text-center Евгению Елескину
    //      .text-3xl.font-cormorant.text-center.mb-20 из г. Вологда!
    //
    //      .text-3xl.font-cormorant.text-center А так же поздравляем
    //      .text-3xl.font-cormorant.text-center <span class="font-cormorantBold">Наталью Кумирову</span> из г. Михайловск,
    //      .text-3xl.font-cormorant.text-center она получает приз зрительских симпатий!
    //
    //    .w-100.bg-red-500(style="height:2px")
    //
    //modal(name="winner-mobile" :height="500" :maxWidth="360" :adaptive="true")
    //  .p-4.flex.flex-col.justify-between.h-full.items-center.border-4
    //    .flex.flex-col.items-center
    //      .w-150.bg-red-500.mb-20(style="height:2px")
    //      .text-2xl.font-cormorant.text-center Поздравляем победителя проекта
    //      .text-4xl.font-cormorantBold.text-center Евгению Елескину
    //      .text-2xl.font-cormorant.text-center.mb-20 из г. Вологда!
    //
    //      .text-2xl.font-cormorant.text-center А так же поздравляем
    //      .text-2xl.font-cormorant.text-center <span class="font-cormorantBold">Наталью Кумирову</span><br> из г. Михайловск,
    //      .text-2xl.font-cormorant.text-center она получает<br> приз зрительских симпатий!
    //
    //    .w-100.bg-red-500(style="height:2px")

</template>

<script>
import { _openModal_ } from './mixins/modals'
export default {
  name: 'App',
  mixins: [_openModal_],
  computed: {
    isMobile() {
      return window.innerWidth <= 500 ? true : false
    }
  },
  mounted() {
    if (this.isMobile) {
      this._openModal_({ name: 'winner-mobile' })
    } else {
      this._openModal_({ name: 'winner' })
    }
  }
}
</script>

<style lang="scss">
// @import './assets/styles/main';

</style>
