/* eslint-disable no-unused-vars */
import Vue from 'vue';
const $vm = Vue.prototype;

class BaseService {
    constructor(resource) {
        if (!resource) throw new Error('Path not provided');
        // this.baseUrl = 'api/v1/';
        // this.path = this.baseUrl + resource;
        this.path = resource; 
    }

    errorHandler(data) {
        return data;
    }

    url({ id }) {
        const path = this.path;
        return id ? path.concat('/', id) : path;
    }
}

class ReadOnlyService extends BaseService {
    constructor(resource) {
        super(resource)
    }

    async get({ 
        id = '', 
        params = {} 
    } = {}) {
        try {
            const url = this.url({ id });
            const { data } = await $vm.$http(url, {params: {...params}});
            return { data }; 
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

class ModelService extends BaseService {
    constructor(resource) {
        super(resource)
    }
    
    async post({ 
        method = 'POST', 
        headers, 
        id = '', 
        body = {} 
    } = {}) {
        try {
            const url = this.url({ id });
            const { data } = await $vm.$http(url, {
                method: method,
                data: body,
                headers: headers,
            })

            return { data };
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

class PatchService extends BaseService {
    constructor(resource) {
        super(resource)
    }
    
    async patch({ 
        method = 'PATCH', 
        headers, 
        id = '', 
        body = {} 
    } = {}) {
        try {
            const url = this.url({ id });
            
            const { data } = await $vm.$http(url, {
                method: method,
                data: body,
                headers: {...headers},
            })

            return { data };
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

class DeleteService extends BaseService {
    constructor(resource) {
        super(resource)
    }
    
    async delete({ 
        method = 'DELETE', 
        id = '', 
        body = {}
    } = {}) {
        try {
            const url = this.url({ id });
            
            const { data } = await $vm.$http(url, {
                method: method,
                data: body,
            })

            return { data };
        } catch (error) {
            this.errorHandler(error.response.data);
        }
    }
}

// Files

class GetFiles extends ReadOnlyService {
    constructor() {
        super('files/get-details')
    }
}

class PostFiles extends ModelService {
    constructor() {
        super('files/add-file')
    }
}

class PostFileDetails extends ModelService {
    constructor() {
        super('files/add-details')
    }
}

class EditFile extends PatchService {
    constructor() {
        super('files/edit')
    }
}

class DeleteFile extends DeleteService {
    constructor() {
        super('files/remove')
    }
}

//User Posts
class AddUserPost extends ModelService {
    constructor() {
        super('user_post/add')
    }
}

class GetUserPosts extends ReadOnlyService {
    constructor() {
        super('user_post/collection')
    }
}

class GetTotalUserPosts extends ReadOnlyService {
    constructor() {
        super('user_post/total')
    }
}

class EditUserPost extends PatchService {
    constructor() {
        super('user_post/edit')
    }
}

class DeleteUserPost extends DeleteService {
    constructor() {
        super('user_post/remove')
    }
}

//Quiz
class AddQuiz extends ModelService {
    constructor() {
        super('quiz/add')
    }
}

class GetQuiz extends ReadOnlyService {
    constructor() {
        super('quiz/collection')
    }
}

class EditQuiz extends PatchService {
    constructor() {
        super('quiz/edit')
    }
}

class DeleteQuiz extends DeleteService {
    constructor() {
        super('quiz/remove')
    }
}

//Participants
class GetParticipants extends ReadOnlyService {
    constructor() {
        super('participants/collection')
    }
}

class AddLike extends PatchService {
    constructor() {
        super('participants/add-like')
    }
}

export const $api = {    
    getFiles: new GetFiles(),
    editFile: new EditFile(),
    addFile: new PostFiles(),
    addFileDetails: new PostFileDetails(),
    deleteFile: new DeleteFile(),    
    addUserPost: new AddUserPost(),
    getUserPosts: new GetUserPosts(),
    editUserPost: new EditUserPost(),
    deleteUserPost: new DeleteUserPost(),
    getTotal: new GetTotalUserPosts(),
    addQuiz: new AddQuiz(),
    qetQuiz: new GetQuiz(),
    editQuiz: new EditQuiz(),
    deleteQuiz: new DeleteQuiz(),
    getParticipants: new GetParticipants(),
    addLike: new AddLike(),
}