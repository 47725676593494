const UserPostsNameSpace = 'userposts';

const UserPostsActionTypes = Object.freeze({
    GetUserPosts: 'GetUserPosts',
    AddUserPost: 'AddUserPost',
    EditUserPost: 'EditUserPost',
    DeleteUserPost: 'DeleteUserPost',
    FindUserPost: 'FindUserPost',
    GetTotal: 'GetTotal',
});

const UserPostsMutationTypes = Object.freeze({
    SetUserPosts: 'SetUserPosts',
    SetChangedUserPost: 'SetUserPost',
    SetDeletedUserPost: 'SetDeletedUserPost',
    SetEmptyUserPost: 'SetEmptyUserPost',
    SetTotal: 'SetTotal',
});

const UserPostsGetterTypes = Object.freeze({
    GetUserPosts: 'GetUserPosts',
    GetTotal: 'GetTotal',
});

export {
    UserPostsNameSpace,
    UserPostsActionTypes,
    UserPostsMutationTypes,
    UserPostsGetterTypes,
};