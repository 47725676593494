import Vue from 'vue';
import Router from 'vue-router';
import { apply } from './apply';
import { details } from './details';
import { quiz } from './quiz';
import { map } from './map';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            meta: {
                auth: true,
            },
            component: () => 
                import(/* webpackChunkName: "Home" */ '@/views/Home'),
        },
        ...apply,
        ...details,
        ...quiz,
        ...map,
    ]
})

export default router