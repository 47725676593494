import Vue from 'vue';
import Vuex from 'vuex';
import { state } from './state';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

import files from "./files";
import userposts from './user-posts';
import quiz from './quiz';
import participants from './participants';

import api from '../plugins/index';
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [api],
  state,
  getters,
  mutations,
  actions,
  modules: {
    files,
    userposts,
    quiz,
    participants,
  }
})
