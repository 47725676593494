const quiz = [{
        path: '/MAsVmR',
        name: 'Quiz',
        meta: {
            auth: true,
        },
        component: () => 
            import(/* webpackChunkName: "Quiz" */ '@/views/quiz'),    
    },
    {
        path: '/quiz-reset',
        name: 'QuizReset',
        meta: {
            auth: true,
        },
        component: () => 
            import(/* webpackChunkName: "Quiz Reset" */ '@/views/quiz/reset'),
    }
]

export {quiz}