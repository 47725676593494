const map = [{
    path: '/yCKRdq',
    name: 'Map',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "Map" */ '@/views/map'),
}]

export {map}