const details = [{
    path: '/details',
    name: 'Details',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "Details" */ '@/views/details/'),
}]

export {details}