const apply = [{
    path: '/apply',
    name: 'Apply',
    meta: {
        auth: true,
    },
    component: () => 
        import(/* webpackChunkName: "Apply" */ '@/views/apply/'),
}]

export {apply}